import { Component, OnInit, Inject } from '@angular/core';
import {
  Router,
  NavigationEnd
} from '@angular/router';
import { WindowService } from '@core/window.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from '@services/configuration.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-web',
  styleUrls: ['app.component.scss'],
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    protected windowRef: WindowService,
    protected translate: TranslateService,
    protected config: ConfigurationService,
  ) {
    this.translate.setDefaultLang('pt-BR');
    this.translate.use('pt-BR');
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
      }
    });
  }

  ngOnInit() {
    const config = this.config.getConfig();
    this.loadStyle(config.style);
  }

  protected loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const themeLink = this.document.getElementById('client-theme') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;
      head.appendChild(style);
    }
  }
}
