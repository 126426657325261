import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import * as Raven from 'raven-js';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '@environment';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { ConfigurationService } from '@services/configuration.service';
import { WindowService } from '@core/window.service';


// --- SENTRY [START] ---
export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    Raven.captureException(err.originalError || err);
  }
}
export function provideErrorHandler() {
  if (environment.hasOwnProperty('sentry')) {
    if (environment.sentry !== null) {
      Raven.config(environment.sentry, {environment: environment.envName}).install();
      return new RavenErrorHandler();
    }
  }
  return new ErrorHandler();
}
// --- SENTRY [END] ---

// --- TRANSLATE [START] ---
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?version=' + environment.version);
}
// --- TRANSLATE [END] ---



@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot({
      prefix: 'PinPag:Partner',
      separator: ':',
      caseSensitive: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    {provide: ErrorHandler, useFactory: provideErrorHandler},
    WindowService,
    ConfigurationService
  ],
})
export class AppModule {}
