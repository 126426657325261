import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environment';

@Injectable()
export class ConfigurationService {
  private ITURAN = 'Ituran';
  private WD = 'Webdespachantes';
  private PAYN = 'Payn';
  private AUTOSRCORE = 'Autoscore';

  private WD_ORIGIN = 'WEBDESPACHANTES';
  private ITURAN_ORIGIN = 'ITURAN';
  private PAYN_ORIGIN = 'PAYN';
  private AUTOSRCORE_ORIGIN = 'AUTOSRCORE';

  private CONFIGS = {
    [this.WD_ORIGIN]: {
      name: this.WD,
      style: 'customization-wd.css?version=' + environment.version,
      origin: this.WD_ORIGIN,
      logo: ['assets/images/pinpag.png'],
      footer_logo: '',
      email: 'sac@webdespachantes.com.br',
      phone: '(11) 4223-7590',
    },
    [this.ITURAN_ORIGIN]: {
      name: this.ITURAN,
      style: 'customization-ituran.css?version=' + environment.version,
      origin: this.ITURAN_ORIGIN,
      logo: ['assets/images/logo-ituram.png'],
      footer_logo: '',
      email: 'sac@pinpag.com.br',
      phone: '(11) 4223-7590',
    },
    [this.PAYN_ORIGIN]: {
      name: this.ITURAN,
      style: 'customization-payn.css?version=' + environment.version,
      origin: this.PAYN_ORIGIN,
      logo: ['assets/images/logo-payn.png'],
      footer_logo: '',
      email: 'sac@pinpag.com.br',
      phone: '(11) 4223-7590',
    },
    [this.AUTOSRCORE_ORIGIN]: {
      name: this.AUTOSRCORE,
      style: 'customization-autoscore.css?version=' + environment.version,
      origin: this.AUTOSRCORE_ORIGIN,
      logo: ['assets/images/logo-autoscore.png'],
      footer_logo: '',
      email: 'sac@pinpag.com.br',
      phone: '(11) 4223-7590',
    },
  };

  get config(): {name: string, style: string, origin: string, logo: string[], footer_logo: string, email: string, phone: string} {
    return this.getConfig();
  }

  constructor(@Inject(DOCUMENT) private document: Document) {}

  protected getConfigUrl(): string {
    const url = this.document.URL;

    if (url.includes('ituran')) {
      return this.ITURAN_ORIGIN;
    }

    if (url.includes('payn')) {
      return this.PAYN_ORIGIN;
    }

    if (url.includes('autoscore')) {
      return this.AUTOSRCORE_ORIGIN;
    }

    // default
    return this.WD_ORIGIN;
  }

  public getConfig() {
    return this.CONFIGS[this.getConfigUrl()];
  }
}
