import { VERSION } from './version';


export const environment: any = {
  production: false,
  envName: 'Homolog',
  version: VERSION + '-beta',
  appBaseUrl: 'https://integrador.homolog.pinpag.com.br/web-despachantes',
  xApiKey: 'F78mmlPU4a5KvmIEMfesS4qX5ltxywJC4Mc5eegV',
};
